<template>
	<div>
		<el-drawer :wrapperClosable="false" append-to-body :visible.sync="dialogVisible2"
			:direction="'rtl'" size="1000px">
			<template :slot="'title'">
				<div>
					<span>
						SKU条形码下载
					</span>
					<span v-if="IsCodeSelect">(按勾选顺序)</span>
					<span v-else>(按筛选条件)</span>
				</div>
				
			</template>
			<div style="padding:10px;">

				<el-alert :title="$t('i18nn_6c2519a4169a3bd4')" type="warning" show-icon v-if="!IsCodeSelect">
					<ul style="padding: 10px; font-size:14px;line-height: 180%;">
						<li>1、按筛选条件排序；</li>
						<li>2、数据量越大，等待时间越长，请筛选后，分批下载；</li>
						<li>3、只下载前1000条数据，超过1000请筛选后，分批下载；</li>
						<li>4、建议使用条码设计，设计合适的尺寸；</li>
						<li>5、可下载EXCEL或图片，也可以直接右键直接另存；</li>
					</ul>
				</el-alert>
				<el-alert :title="$t('i18nn_6c2519a4169a3bd4')" type="warning" show-icon v-else>
					<ul style="padding: 10px; font-size:14px;line-height: 180%;">
						<li>1、按勾选顺序排序；</li>
						<li>2、建议使用条码设计，设计合适的尺寸；</li>
						<li>3、可下载EXCEL或图片，也可以直接右键直接另存；</li>
					</ul>
				</el-alert>
				<div v-loading="loading_pdf" :element-loading-text="'loading..'">
					<el-button type="primary" @click="downLoadPdf()"
						icon="el-icon-download"><span>{{$t('i18nn_245883c80f181c4a')}}</span>PDF</el-button>
					<el-button type="warning" icon="el-icon-download"
						@click="exportExcelAction2()"><span>{{$t('i18nn_245883c80f181c4a')}}</span>Excel</el-button>
					<el-button type="warning" icon="el-icon-download"
						@click="downLoadImg()"><span>{{$t('i18nn_245883c80f181c4a')}}</span>图片</el-button>

					<el-button type="success" @click="openConfig()"
						icon="el-icon-brush">{{$t('i18nn_47c222b26ca4ce78')}}</el-button>
					<div class="expBtnCon" v-if="!this.IsCodeSelect">
						<div class="expBtn">
							<el-button type="primary" size="small" plain icon="el-icon-refresh" @click="loadData(0)">
								<span>
									<span>{{$t('i18nn_5f7f4143c0564b54')}}</span>1-{{pageSize}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</span>
							</el-button>
						</div>
						<div class="expBtn" v-for="(item,index) in batchNumList" :key="index">
							<el-button type="primary" size="small" plain icon="el-icon-refresh" @click="loadData(item.start)"
								v-if="total > item.start">
								<span>{{$t('i18nn_5f7f4143c0564b54')}}</span>{{item.start+1}}-{{item.end}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span></el-button>
						</div>
					</div>

					<div style="padding: 10px; font-size:14px;line-height: 180%;" v-if="!this.IsCodeSelect">
						<strong>
							当前可下载第 {{activeStart+1}}-{{ activeEnd }} 数据，共 {{total}} 条数据；
						</strong>
					</div>


					<div id="pdfPage_sku" ref="pdfPage" style="background-color: #f5f5f5;">

						<div class="codeBarListCon">
							<div class="codeBarItem" v-for="(item, index) in PdfData" :key="index">
								<div class="codeBarIndex">
									{{ activeStart+index + 1 }}
								</div>
								<!-- <div class="codeBar" :class="{
									'printer_size_1':'1'==form.size,
									'printer_size_2':'2'==form.size,
									'printer_size_3':'3'==form.size,
									'printer_size_4':'4'==form.size,
									'printer_size_5':'5'==form.size,
									'printer_size_6':'6'==form.size
								}"> -->
								<div class="codeBar">
								<div style="text-align: center;">
									
									<imgSku :skuData="item" :option="form"></imgSku>
									
									<!-- <div>
										<div class="code_img" style="text-align: center;">
											<barcode :code="item.goodsSku" :options="{height:100,width:2,fontSize:30}"></barcode>
										</div>
										
										<div class="code_name" v-show="form.showSkuName">
											<imgFont :text="item.goodsName" :options="{
												width: 400,
												height: 25,
												fontSize: 20,
											}"></imgFont>
										</div>
										<div class="code_user" v-show="form.showCus">
											{{item.userId}}
										</div>
										<div class="code_mark" v-show="form.addText && form.addText.length>0">
											<div class="other-text">
												<span v-for="(item,index) in form.addText" :key="index">
													{{item}}
												</span>
											</div>
										</div>
									</div> -->
								</div>
									
									
								</div>
							</div>
						</div>


						<!-- <table class="code_prt_tabel">
						<tbody v-for="(item, index) in PdfData" :key="index" style="background-color: #fff; border-bottom: 5px  solid #eee">
							<tr>
								<td rowspan="4">{{ activeStart+index + 1 }}</td>
								<td>
									<barcode :code="item.goodsSku" :options="{height:100,width:2,fontSize:30}"></barcode>
								</td>
							</tr>
							<tr v-show="form.showSkuName" style="font-size: 24px;">
								<td>
									<span class="over_ellipsis">
										{{item.goodsName}}
									</span>
										
								</td>
							</tr>
							<tr v-show="form.showCus" style="font-size: 12px;">
								<td>
									{{item.userId}}
								</td>
							</tr>
							<tr v-if="form.addText && form.addText.length>0">
								<td>
									<div class="other-text">
										<span v-for="(item,index) in form.addText" :key="index">
											{{item}}
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						
					</table> -->

					</div>
				</div>

			</div>
			<!-- <div class="drawer-footer">
			<el-button type="primary" @click="downLoadPdf()"
				icon="el-icon-download"><span>{{$t('i18nn_245883c80f181c4a')}}</span>PDF</el-button>
			<el-button type="warning" icon="el-icon-download"
				@click="exportExcelAction2()"><span>{{$t('i18nn_245883c80f181c4a')}}</span>Excel</el-button>

			<el-button type="primary" plain @click="dialogVisible2 = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
		</el-drawer>

		<!-- 配置设计 -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_47c222b26ca4ce78')" append-to-body
			:visible.sync="dialogVisibleConfig" :direction="'rtl'" size="500px">
			<el-form ref="form" :rules="formRules" :model="form" label-width="120px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<el-form-item :label="$t('i18nn_a85af4ba66084d28')+'(mm)'">
					<el-radio-group v-model="form.size">
						<el-radio v-for="(item,index) in selectOption.sizeList" :key="index" :label="item.value">
							{{item.label}}
						</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- <el-form-item :label="'显示logo'">
				<el-switch v-model="form.showLogo" :active-text="$t('i18nn_02ccd2cf723f9272')" :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
				</el-switch>
			</el-form-item> -->

				<el-form-item :label="'显示SKU名称'">
					<el-switch v-model="form.showSkuName" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="'显示客户ID'">
					<el-switch v-model="form.showCus" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="$t('i18nn_ee7bdbe4f27e38df')">
					<el-checkbox-group v-model="form.addText">
						<el-checkbox v-for="(item,index) in selectOption.addTextList" :key="index" :label="item"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>

			</el-form>
			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogVisibleConfig = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitForm()">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	import JQ from 'jquery';

	import barcode from '@/components/Common/barcode.vue';

	import imgFont from '@/components/Common/imgFont.vue';
	
	import imgSku from '@/components/Common/imgSku.vue';

	import excelUtilsNew from '@/utils/excelUtilsNew.js';

	// import { htmlToPdf } from '@/utils/pdfUtils.js';
	import {
		exportPdfBySku,
		exportImgBySku
	} from '@/utils/pdfExport.js'

	export default {

		props: {
			openTime: {},
			filter: {},
			IsCodeSelect: {},
			multipleSelection: {},
		},
		components: {
			barcode,
			imgFont,
			imgSku
		},
		data() {
			return {
				dialogVisible2: false,
				loading_pdf: false,
				PdfData: [],
				pageSize: 500,
				total: 0,
				activeStart: 0,
				activeEnd: 0,

				batchNumList: [],

				selectOption: {
					sizeList: [{
							label: "150*50",
							value: "1"
						}, {
							label: "150*80",
							value: "2"
						}, {
							label: "150*100",
							value: "3"
						}, {
							label: "200*100",
							value: "4"
						}, {
							label: "300*100",
							value: "5"
						}
						// , {
						// 	label: "400*150",
						// 	value: "6"
						// }
					],
					addTextList: ["New", "Made in China"]
				},

				dialogVisibleConfig: false,
				loading: false,
				skuLenMax: 0,
				form: {
					size: "3",
					// showLogo: true,
					showCus: false,
					showSkuName: true,
					addText: []
				},
				formRules: {

				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogVisible2 = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				// this.getPdfData();
				if (this.IsCodeSelect) {
					this.PdfData = this.multipleSelection.map(item=>{
						return {
							goodsSku: item.goodsSku,
							goodsName: item.goodsName,
							userId: item.userId,
						}
					});
					if (this.PdfData && this.PdfData.length > 0) {
						let skuLenList = this.PdfData.map(item => {
							return item.goodsSku.length;
						});
						console.log('skuLenList', skuLenList);
						this.form.size = this.setDefSize(skuLenList);
					}
				} else {
					this.loadData(0);
				}

			},

			exportExcelAction2() {
				// this.exportExcel(this.PdfData);
				let columns = [{
					title: 'SKU CODE',
					key: 'barcodeimg',
					types: 'image'
				}];
				// let Data = this.tableDataCatch;
				// return columns;
				let Data = this.PdfData;

				let data_dom = JQ('#pdfPage_sku').find('.codeBarListCon');
				let obj_key_img = {};
				// console.log(data_dom.find("tr"));
				data_dom.find('.codeBarItem').each((index, domEle) => {
					// console.log("index",index);
					let imgObj = JQ(domEle).find('.codeBar img.imgSku');
					// console.log("imgObj",imgObj);
					if (imgObj) {
						obj_key_img[imgObj.attr('code')] = imgObj.attr('src');
						obj_key_img[imgObj.attr('code') + '_ExImgWidth'] = imgObj.width();
						obj_key_img[imgObj.attr('code') + '_ExImgHeight'] = imgObj.height();
					}
				});
				// console.log("obj_key_img",obj_key_img);
				Data.forEach(item => {
					item.barcodeimg = obj_key_img[item.goodsSku];
					item.ExImgWidth = obj_key_img[item.goodsSku + '_ExImgWidth'];
					item.ExImgHeight = obj_key_img[item.goodsSku + '_ExImgHeight'];
				});
				// console.log('Data',Data);
				// return;
				let img_height = 150;
				try {
					img_height = JQ('#pdfPage_sku').find('.codeBarListCon .codeBarItem').eq(0).find('.codeBar img.imgSku').eq(0).height();
					console.log('height',img_height)
				} catch(err){
					console.log(err);
				}
				
				excelUtilsNew.exportExcel(columns, Data, 'wh_sku',{
					height: parseInt(img_height)
				});
			},

			//下载pdf
			downLoadPdf() {
				this.loading_pdf = true;
				this.$nextTick(async () => {
					await exportPdfBySku("whSku_barcode_", "#pdfPage_sku", this.form);
					this.loading_pdf = false;
				});
			},

			//下载图片
			downLoadImg() {
				this.loading_pdf = true;
				this.$nextTick(async () => {
					console.log('this.loading_pdf11', this.loading_pdf);
					await exportImgBySku("whSku_Img_", "#pdfPage_sku");
					this.loading_pdf = false;
					console.log('this.loading_pdf22', this.loading_pdf);
				});
			},

			//打开设计
			openConfig() {
				this.dialogVisibleConfig = true;
			},
			//设计
			submitForm() {
				// this.initData();
				this.dialogVisibleConfig = false;
			},

			loadData(start) {
				this.getPdfData(start, this.pageSize);
			},

			//设置面单默认尺寸
			setDefSize(numList) {
				let size = '';
				let max = Math.max(...numList);
				this.skuLenMax = max;
				if (max >= 22) {
					size = '5';
				} else if (max >= 15) {
					size = '4';
				} else if (max >= 13) {
					size = '3';
				} else {
					size = '3';
				}
				return size;
			},

			getPdfData(offset, limit) {
				this.loading_pdf = true;

				let filterData = Object.assign({
						offset: offset, //当前页
						limit: limit //当前页显示数目
					},
					this.filter
				);

				this.$http
					.put(this.$urlConfig.WhMyGoodsSkuPageList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						this.loading_pdf = false;
						if (200 == data.code && data.rows) {
							//表格显示数据
							this.PdfData = data.rows.map(item=>{
								return {
									goodsSku: item.goodsSku,
									goodsName: item.goodsName,
									userId: item.userId,
								}
							});

							if (this.PdfData && this.PdfData.length > 0) {
								let skuLenList = this.PdfData.map(item => {
									return item.goodsSku.length;
								});
								console.log('skuLenList', skuLenList);
								this.form.size = this.setDefSize(skuLenList);
							}

							this.total = data.total;
							this.activeStart = offset;
							this.activeEnd = offset + this.PdfData.length;

							// //计算分页,不计算第一个分页
							this.calcPage();

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_pdf = false;
					});
			},

			//计算分页的起始和结束，不计算第一个分页
			calcPage() {
				let batchNumList = [];
				let pageSize = this.pageSize;

				//计算分页,不计算第一个分页
				if (this.total < pageSize) {
					// batchNumList.push({
					// 	start:0,
					// 	end:this.allExpDataTotal
					// });
				} else {
					//计算一共有多少分页
					let batchPage = Math.ceil(this.total / pageSize);
					console.log('batchPage', batchPage);
					for (let i = 1; i < batchPage; i++) {
						let end = (i + 1) * pageSize;
						if (end > this.total) {
							end = this.total;
						}
						batchNumList.push({
							start: i * pageSize,
							end: end
						});
					}
				}
				console.log('batchNumList', batchNumList);
				this.batchNumList = batchNumList;
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.expBtnCon {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.expBtn {
			margin-right: 10px;
		}
	}

	.codeBarListCon {
		background-color: #f5f5f5;

		.codeBarItem {
			display: flex;
			align-items: center;
			margin: 10px 0;

			.codeBarIndex {
				font-size: 20px;
				font-weight: bold;
				padding: 10px;
			}

			.codeBar {
				border: 1px solid #ccc;
				// background-color: #fff;
				// padding: 20px 5px 5px 10px;
				overflow: hidden;

				.code_img {}

				.code_name {
					font-size: 20px;
				}

				.code_user {
					font-size: 12px;
				}

				.code_mark {}
			}
		}

	}

	// .code_prt_tabel {
	// 	border-collapse:collapse;
	// 	tr ,td,th {
	// 		border:none;

	// 	}
	// }

	.printer_size_1 {
		width: calc(150mm/1.34);
		height: calc(50mm/1.34);
	}

	.printer_size_2 {
		width: calc(150mm/1.34);
		height: calc(80mm/1.34);
	}

	.printer_size_3 {
		width: calc(150mm/1.34);
		height: calc(100mm/1.34);
	}

	.printer_size_4 {
		width: calc(200mm/1.34);
		height: calc(100mm/1.34);
	}

	.printer_size_5 {
		width: calc(300mm/1.34);
		height: calc(100mm/1.34);
	}

	.printer_size_6 {
		width: calc(400mm/1.34);
		height: calc(100mm/1.34);
	}

	.other-text {
		// text-align: right;
		font-size: 14px;
		line-height: 150%;
		margin-top: 5px;
		display: flex;
		justify-content: space-between;
		// align-items: flex-end;
	}
</style>